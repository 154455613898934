<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" @change="agentChange" v-if="isAdmin" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 200px">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" style="width: 200px" v-model="query.companyId" @change="companyChange"
					filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" class="m_l_10"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" class="m_l_10"
					@click="setVisible = true">设置自动回收</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column prop="userPhone" label="用户" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" label="回收号码" align="center"></el-table-column>
				<el-table-column prop="createdAt" label="时间" align="center"></el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[10, 30, 50, 100, 300]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>

			<el-dialog v-model="setVisible" title="自动回收设置" width="450px" :close-on-click-modal="false">
				<el-form :model="query" ref="query" label-width="100px">
					<el-form-item label="代理商" prop="agentId" v-if="isAdmin">
						<el-select size="small" @change="agentChange" v-model="query.agentId" filterable clearable
							placeholder="代理商" class="handle-select mr10">
							<el-option v-for="item in agents" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="子代理" prop="subAgentId" v-if="isSubAgent">
						<el-select size="small" v-model="query.subAgentId" @change="getfetchDefaultCompanies" filterable
							clearable placeholder="子代理" class="handle-select mr10">
							<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="企业" prop="companyId">
						<el-select size="small" v-model="query.companyId" @change="companyChange" filterable clearable
							placeholder="企业" class="handle-select mr10">
							<el-option v-for="item in companys" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="自动回收" prop="enable">
						<el-switch v-model="query.enable" active-color="#13ce66" inactive-color="#ff4949"
							class="m_l_10"></el-switch>
					</el-form-item>
					<el-form-item label="成交客户回收" prop="complete" v-if="query.enable">
						<el-switch v-model="query.complete" active-color="#13ce66" inactive-color="#ff4949"
							class="m_l_10"></el-switch>
					</el-form-item>

					<div style="margin-left: 42px;">回收超出<el-select size="small" v-model="query.day"
							style="width: 120px;margin:0 6px;" filterable allow-create clearable>
							<el-option v-for="item in dayList" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>天未跟进客户自动回收</div>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;margin-bottom: 0;">
						<el-button size="small" @click="setVisible = false">取消</el-button>
						<el-button type="primary" :class="[theme]" size="small"
							@click="getAutomaticRecovery">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		SnailErrRecord
	} from '../api/dijia.js';
	import {
		fetchCompanyByAgentId,
		fetchDefaultCompanies
	} from '../api/companyIndex';
	import {
		fetchAgent,
		AutomaticRecovery,
		RecoveryRecord,
		getAutomaticRecovery
	} from '../api/agentIndex';

	export default {
		name: 'company',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					userId: localStorage.getItem('user'),
					pageIndex: 1,
					pageSize: 10,
					subAgentId: '',
					enable: false,
					complete: false,
				},
				agents: [],
				companys: [],
				isSubAgent: false,
				setVisible: false,
				options: [],
				tableData: [],
				pageTotal: 0,
				timeArr: [],
				dayList: [{
					value: "3",
					label: '3天',
				}, {
					value: "7",
					label: '7天',
				}, {
					value: "15",
					label: '15天',
				}, {
					value: "30",
					label: '30天',
				}]
			};
		},
		created() {
			let role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (role === 'agent') {
				this.isAgent = true;
				this.isNotAdmin = false;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (role === 'subAgent') {
				this.isAgent = true;
			}
			if (role === 'admin') {
				this.isAdmin = true;
				this.isAgent = true;
				this.isNotAdmin = false;
			}
			this.init();
		},
		methods: {

			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getRecoveryRecord();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getRecoveryRecord();
			},
			// 分页导航
			handlePageChange(val) {
				if (val) {
					this.query.pageIndex = val;
				} else {
					this.query.pageIndex = 1;
				}
				this.getRecoveryRecord();
			},

			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					//代理
					this.agents = res.data;
					this.options = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				//代理商下拉
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			//通过子代理获取企业
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			//子代理
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getRecoveryRecord() {
				let data = {
					companyId: this.query.companyId,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
					userId: localStorage.getItem('user')
				};
				RecoveryRecord(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.pageTotal;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getAutomaticRecovery(e) {
				let data = {
					userId: localStorage.getItem('user'),
					companyId: this.query.companyId,
					enable: this.query.enable,
					complete: this.query.complete,
					day: this.query.day
				};
				AutomaticRecovery(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.setVisible = false;
						this.query.day = '';
						this.query.enable = false;
						this.query.complete = false;
						this.query.companyId = '';
						this.query.agentId = '';
					} else {
						this.$message.error(res.message);
					}
				});
			},
			companyChange() {
				getAutomaticRecovery(this.query.companyId).then(res => {
					if (res.code == 200) {
						this.query.enable = res.data.enable;
						this.query.complete = res.data.complete;
						this.query.day = res.data.day;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleInput(value) {
				const regex = /^[1-9]\d*$/;
				if (!regex.test(value)) {
					this.query.day = this.query.day.substring(0, this.query.day.length - 1);
				}
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}
</style>